<template>
  <div class="container px-4 pt-8 mx-auto lg:px-4">
    <StockDetail :stock="stock" />
  </div>
</template>

<script>
import iex from '../util/iex';

import StockDetail from '@/components/StockDetail.vue';

export default {
  name: 'StockView',
  components: {
    StockDetail
  },
  props: {
  },
  data() {
    return {
      stock: {
        symbol: this.$route.params.symbol,
        chart: null,
        company: {},
        logo: { url: '' },
        quote: {}
      }
    }
  },
  mounted() {
    this.loadStock(this.stock.symbol);
  },
  methods: {
    loadStock(symbol) {
      iex.getLogo(symbol).then((resp) => {
        console.log(resp.data);
        this.stock.logo = resp.data;
      });
      iex.getCompany(symbol).then((resp) => {
        console.log(resp.data);
        this.stock.company = resp.data;
      });
      iex.getQuote(symbol).then((resp) => {
        console.log(resp.data);
        this.stock.quote = resp.data;
      });
      iex.getChart(symbol).then((resp) => {
        console.log(resp.data);
        this.stock.chart = resp.data;
      });
    }
  }
}
</script>
