import axios from 'axios';

const makeRequest = async (uri, params = {}) => {
  return axios.get(`${process.env.VUE_APP_IEX_API_URL}/${uri}`, { params: { token: process.env.VUE_APP_IEX_PUBLIC_KEY, ...params } })
};

export default {

  async getCompany (symbol) {
    return makeRequest(`stock/${symbol}/company`);
  },

  async getChart (symbol, range = '1m') {
    return makeRequest(`stock/${symbol}/chart/${range}`, { includeToday: true, changeFromClose: true, chartByDay: true, sort: 'asc' });
  },

  async getLogo (symbol) {
    return makeRequest(`stock/${symbol}/logo`);
  },

  async getQuote (symbol) {
    return makeRequest(`stock/${symbol}/quote`);
  }

}
