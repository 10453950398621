<template>
  <div>
    <div class="grid grid-flow-col auto-cols-auto mb-4">
      <div class="w-1/4">
        <img :src="stock.logo.url" />
      </div>
      <div>
        <div class="text-5xl">
          {{ stock.company.symbol }}
        </div>
        <h2 class="text-gray-500">{{ stock.company.companyName }}</h2>
        <h5 class="text-gray-300">{{ stock.company.exchange }}</h5>
      </div>
      <div class="text-right">
        <div>
          <span class="mr-2 font-bold text-gray-500">{{ stock.quote.currency }}</span>
          <span class="text-5xl">{{ stock.quote.latestPrice }}</span>
        </div>
        <h2 class="text-green-500">
          {{ stock.quote.change }}
          <span class="">({{ Math.round((stock.quote.changePercent + Number.EPSILON) * 100 * 100) / 100 }}%)</span>
        </h2>
        <h5 class="text-gray-300">{{ stock.quote.latestTime }}</h5>
      </div>
    </div>

    <p class="mb-8">{{ stock.company.description }}</p>

    <v-calendar
      class="custom-calendar max-w-full mb-8"
      :masks="masks"
      :attributes="attributes"
      disable-page-swipe
      is-expanded
    >
      <template v-slot:day-content="{ day }">
        <div class="flex flex-col h-full z-10 overflow-hidden" :class="{ 'red': isDayRed(day), 'green': isDayGreen(day) }">
          <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
          <div class="flex-grow overflow-y-auto overflow-x-auto text-center">
            <div v-if="itemForDay(day)">
              <h2>
                {{ Math.round((itemForDay(day).change + Number.EPSILON) * 100) / 100 }}
              </h2>
              <h3>
                {{ Math.round((itemForDay(day).changePercent * 100 + Number.EPSILON) * 100) / 100 }}%
              </h3>
            </div>
          </div>
        </div>
      </template>
    </v-calendar>

    <table class="mb-4 table-fixed w-full">
      <thead>
        <tr>
          <th class="text-left">Date</th>
          <th class="text-left">Red/Green/Neutral</th>
          <th class="text-right">Close Price</th>
          <th class="text-right">Change</th>
          <th class="text-right">Change (%)</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in stock.chart" :key="`chart_item_${item.id}`" :class="{ 'bg-red-200': item.change < 0, 'bg-green-200': item.change > 0, 'bg-gray-200': item.change == 0 }">
          <td>{{ item.label }}</td>
          <td>
            <span v-if="item.change < 0" class="text-red-600">
              RED
            </span>
            <span v-else-if="item.change > 0" class="text-green-600">
              GREEN
            </span>
            <span v-else class="text-gray-600">
              NEUTRAL
            </span>
          </td>
          <td class="text-right">
            {{ Math.round((item.close + Number.EPSILON) * 100) / 100 }}
          </td>
          <td class="text-right" :class="{ 'text-red-600': item.change < 0, 'text-green-600': item.change > 0, 'text-gray-600': item.change == 0 }">
            {{ Math.round((item.change + Number.EPSILON) * 100) / 100 }}
          </td>
          <td class="text-right" :class="{ 'text-red-600': item.change < 0, 'text-green-600': item.change > 0, 'text-gray-600': item.change == 0 }">
            {{ Math.round((item.changePercent + Number.EPSILON) * 100 * 100) / 100 }}%
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: 'StockDetail',
  props: {
    stock: Object
  },
  data() {
    return {
      masks: {
        weekdays: 'WWW',
      },
      attributes: [
      ],
    };
  },
  async mounted() {
  },
  methods: {
    getChangeForDay(day) {
      if (this.stock.chart) {
        const item = this.stock.chart.find((item) => item.date == day.id);
        if (item) { 
          return item.change;
        }
      }
    },
    isDayGreen(day) {
      if (this.stock.chart) {
        const item = this.stock.chart.find((item) => item.date == day.id);
        if (item) { 
          return item.change > 0;
        }
      }
      return false;
    },
    isDayRed(day) {
      if (this.stock.chart) {
        const item = this.stock.chart.find((item) => item.date == day.id);
        if (item) { 
          return item.change < 0;
        }
      }
      return false;
    },
    itemForDay(day) {
      if (this.stock.chart) {
        const item = this.stock.chart.find((item) => item.date == day.id);
        if (item) { 
          return item;
        }
      }
    }
  }
}
</script>

<style lang="postcss" scoped>
::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  display: none;
}
/deep/ .custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;
  & .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
  }
  & .vc-weeks {
    padding: 0;
  }
  & .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;
  }
  & .vc-day .red {
    background-color: rgba(255, 187, 187, 0.9);
  }
  & .vc-day .green {
    background-color: rgba(187, 255, 187, 0.9);
  }
  & .vc-day {
    padding: 0 5px 3px 5px;
    text-align: left;
    height: var(--day-height);
    min-width: var(--day-width);
    background-color: white;
    &.weekday-1,
    &.weekday-7 {
      background-color: #eff8ff;
    }
    &:not(.on-bottom) {
      border-bottom: var(--day-border);
      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }
    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }
  & .vc-day-dots {
    margin-bottom: 5px;
  }
}
</style>
