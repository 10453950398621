<template>
  <header class="border-b bg-gray-100">
    <div class="container mx-auto flex flex-col flex-wrap p-5 md:items-center md:flex-row">
      <nav>
        <ul class="flex flex-wrap items-center justify-center text-base">
          <li class="mr-5 text-xl font-mono font-bold hover:text-gray-700">
            ticker
          </li>
          <li class="mr-5 text-base font-semibold hover:text-gray-800">
            <router-link to="/">Home</router-link>
          </li>
          <li class="mr-5 text-base font-semibold hover:text-gray-800">
            <router-link to="/about">About</router-link>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: "NavBar",
};
</script>