import { createApp } from 'vue'
import App from './App.vue'
import VCalendar from 'v-calendar';
import router from './router/index'
import './assets/tailwind.css'
import './index.css';

createApp(App)
  .use(VCalendar, {})
  .use(router)
  .mount('#app')
