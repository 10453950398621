<template>
  <div class="container px-4 pt-8 mx-auto lg:px-4">
    <div class="mb-3 text-4xl">
      About <span class="text-4xl font-mono font-bold">ticker</span>
    </div>
    <p class="mb-8">
      <span class="text-base font-mono font-bold">ticker</span> is a financial focused 
      experiment to visualize and inspect stocks &amp; cryptocurrencies from different perspectives.
    </p>
    <div class="mb-3 text-2xl">
      Where do you get your data?
    </div>
    <p>
      The data we use is paid for at our expense. Data is provided by <a href="https://iexcloud.io/s/34c18555">IEX Cloud</a>
      and is used by huge financial services you might have already used. We highly recommend them.
    </p>
  </div>
</template>

<script>

export default {
  name: 'About',
}
</script>
