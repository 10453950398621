import * as VueRouter from 'vue-router';

import About from '../views/AboutView';
import Home from '../views/Home';
import Stock from '../views/StockView';

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/about', name: 'About', component: About },
  { path: '/stock/:symbol', name: 'Stock', component: Stock },
]

const router = new VueRouter.createRouter({
  history: VueRouter.createWebHistory('/'),
  base: process.env.BASE_URL,
  routes
})

export default router
